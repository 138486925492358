import example1 from './Cart/example1.png'
import close from './Cart/close.svg'
import plus from './Cart/plus.svg'
import minus from './Cart/minus.svg'

export const cart = {
    example1,
    close,
    plus,
    minus
}