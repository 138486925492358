import arrowLeft from './PhotoSlider/ArrowLeft.svg'
import arrowRight from './PhotoSlider/ArrowRight.svg'
import close from './PhotoSlider/Close.svg'
import example1 from './PhotoSlider/Example1.png'

export const photoSlider = {
    arrowLeft,
    arrowRight,
    close,
    example1
}