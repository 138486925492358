import students from './Home/students.svg'
import camera from './Home/cameraBro.svg'
import camera2 from './Home/cameraBro2.svg'
import example1 from './Home/Ильва_800х85х75-0551 1.png'
import email from './Home/email.svg'
import geo from './Home/geo.svg'
import phone from './Home/phone.svg'
import left from './Home/Left.svg'
import right from './Home/Right.svg'
import burger from './Home/burger.svg'
import up from './Home/up.svg'
import close from './Home/close.svg'
import thesisPana from './Home/Thesis-pana.svg'

export const home = {
    students,
    example1,
    camera,
    camera2,
    email,
    geo,
    phone,
    left,
    right,
    burger,
    up,
    close,
    thesisPana
}