export enum ESizes {
    s = 's',
    x = 'x'
}

export enum EType {
    blue = 'blue',
    white = 'white',
    green = 'green',
    border = 'Border'
}