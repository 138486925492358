import close from './CreateAlbum/close.svg'
import edit from './CreateAlbum/edit.svg'
import icoImg from './CreateAlbum/icoImg.svg'
import ex1 from './CreateAlbum/example1.png'

export const createAlbum = {
    close,
    edit,
    icoImg,
    ex1
}